/* src/app/app.component.scss */
.ion-menu-side {
  border-right: none;
  width: auto;
  min-width: 345px;
}
.menu-tab {
  margin: 20px 0px !important;
  cursor: pointer;
}
.side-nav {
  margin-top: 30px !important;
}
ion-note {
  display: inline-block;
  font-size: 15px;
  color: var(--ion-color-medium-shade);
}
ion-item.selected {
  --color: var(--ion-color-primary);
}
ion-avatar {
  width: 45px;
  height: 45px;
}
.partner-logo {
  max-height: 50px !important;
  max-width: 250px !important;
  margin: 5px 0px;
}
.active-link {
  background: red;
}
.custom-skeleton ion-skeleton-text {
  line-height: 13px;
}
.custom-skeleton ion-skeleton-text:last-child {
  margin-bottom: 5px;
}
.sidebar-user-profile:hover {
  cursor: pointer;
}
.sidebar-user-profile {
  margin: 0 5px;
}
@media screen and (max-width: 600px) {
  ion-list-header {
    font-size: 16px;
  }
  ion-note {
    font-size: 12px;
  }
}
ion-grid {
  padding: 0px;
}
.content-margin {
  margin-top: 80px;
}
.footer-margin {
  margin-bottom: 70px !important;
}
.footer-alert {
  margin-left: 270px;
}
ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
  margin: 0px 150px !important;
}
ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 0px;
  --padding-bottom: 0px;
}
ion-menu.md ion-list {
  padding: 0px;
}
ion-menu.md ion-list-header,
ion-menu.md ion-note {
  padding-left: 10px;
}
ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}
ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #879a99;
  min-height: 26px;
}
ion-menu.md ion-item {
  --padding-start: 25px;
  --padding-end: 0px;
  border-radius: 0.85rem;
  min-width: 250px;
}
ion-menu.md ion-item.selected {
  --background: rgba(var(--ion-color-primary-rgb), 0.08) !important;
  border-radius: 0.85rem;
  width: fit-content;
}
ion-menu.md ion-item ion-icon fa-icon {
  font-size: 22px;
  color: #879a99;
}
ion-menu.md ion-item ion-label {
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.5px;
  color: var(--ion-color-text);
}
ion-menu.ios ion-content {
  --padding-bottom: 20px;
}
ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}
ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}
ion-menu.ios ion-item {
  --padding-start: 20px;
  --padding-end: 0px;
  --min-height: 50px;
  border-radius: 0.85rem;
}
ion-menu.ios ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}
ion-menu.ios ion-item ion-icon fa-icon {
  font-size: 22px;
  color: #7b8f8a;
}
ion-menu.ios ion-item ion-label {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.3px;
  color: var(--ion-color-text);
}
ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}
ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}
ion-menu.ios ion-note {
  margin-bottom: 8px;
}
ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}
ion-item.selected {
  --color: var(--ion-color-primary);
}
ion-menu ion-content ion-list {
  margin: 0% 7%;
}
.alert-text {
  align-self: center;
  font-size: 14px;
  letter-spacing: 0.5px;
  font-weight: 600;
}
.alert-info {
  border-radius: 0.85rem;
  background: rgba(9, 94, 83, 0.1);
  margin: 10px 10%;
  border-bottom: 3px solid #095e53;
  padding: 3px 3px 1px 3px;
  color: var(--ion-text-color);
}
.alert-info .alert-line-info {
  width: 5px;
  height: 35px;
  background: #095e53;
  border-radius: 0.85rem;
  margin: 5px 10px 3px 5px;
}
.alert-info .alert-icon-info {
  color: #095e53;
  align-self: center;
  font-size: 22px;
}
.alert-high {
  border-radius: 0.65rem;
  background: rgba(255, 87, 34, 0.1);
  margin: 10px 10%;
  border-bottom: 3px solid #ff5722;
  padding: 3px 3px 1px 3px;
}
.alert-high .alert-line-high {
  width: 5px;
  height: 35px;
  background: #ff5722;
  border-radius: 0.85rem;
  margin: 5px 10px 3px 5px;
}
.alert-high .alert-icon-high {
  color: #ff5722;
  align-self: center;
  font-size: 22px;
}
.alert-medium {
  border-radius: 0.65rem;
  background: rgba(239, 169, 46, 0.1);
  border-bottom: 3px solid #EFA92E;
  padding: 3px 3px 1px 3px;
  margin: 10px 10%;
}
.alert-medium .alert-line-medium {
  width: 5px;
  height: 35px;
  background: #EFA92E;
  border-radius: 0.85rem;
  margin: 5px 10px 3px 5px;
}
.alert-medium .alert-icon-medium {
  color: #EFA92E;
  align-self: center;
  font-size: 22px;
}
.alert-low {
  border-radius: 0.65rem;
  background: rgba(32, 166, 122, 0.1);
  border-bottom: 3px solid #20A67A;
  padding: 3px 3px 1px 3px;
  margin: 10px 10%;
}
.alert-low .alert-line-low {
  width: 5px;
  height: 35px;
  background: #20A67A;
  border-radius: 0.85rem;
  margin: 5px 10px 3px 5px;
}
.alert-low .alert-icon-low {
  color: #20A67A;
  align-self: center;
  font-size: 22px;
}
.toolbar-background {
  background: var(--ion-background-color);
}
.logo {
  display: flex;
  justify-content: center;
  margin-left: 305px;
  outline: none;
  cursor: pointer;
}
@media screen and (max-width: 540px) {
  .content-margin {
    margin-top: 0px;
  }
  ion-router-outlet {
    top: 70px;
  }
  .alert-text {
    font-size: 13px;
  }
  .side-nav {
    margin-top: 90px !important;
  }
}
@media screen and (max-width: 991px) {
  ion-menu ion-content ion-list {
    margin: 0%;
  }
  .logo {
    margin-left: 0px;
    margin-right: 30px;
  }
  .footer-alert {
    margin-left: 0px;
  }
  .cf-logo-width {
    max-width: 220px;
  }
  .side-nav {
    margin-top: 10px !important;
  }
}
@media screen and (max-width: 1500px) {
  ion-menu ion-content ion-list {
    margin: 2%;
  }
  .ion-menu-side {
    min-width: 312px;
  }
}
.account {
  margin: 5px 2px 0px 30px;
  border-top: 1px solid rgba(189, 189, 189, 0.28);
  padding-top: 10px;
}
.profile-picture {
  border-radius: 0.85rem;
  width: 35px;
  height: 35px;
  margin-top: 2px;
}
.menu-tab {
  max-width: 300px;
  margin: 15px 0px;
}
.icon {
  color: var(--ion-color-primary);
  font-size: 20px;
}
/*# sourceMappingURL=app.component.css.map */
